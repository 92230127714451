import React from "react";
import data from "../../constants/data";
import "./services.css";

const Services = () => {
    return (
        <section className="what-we-do py-6" id="what_we_do">
            <div className="container">
                <div className="section-title bg-dark">
                    <h2 className="text-upper text-white text-center">Our Services</h2>
                </div>


                <div className="what-we-do-content">
                    {
                        data.what_we_do.map((whatItem, index) => {
                            return (
                                <div className="what-we-do-item grid text-center" key={index}>
                                    <div className="what-we-do-item-left">
                                        <img src={whatItem.image}
                                            alt="" className="mx-auto" />
                                    </div>

                                    <div className="what-we-do-item-right">
                                        
                                        <h4 className="text-upper fs-20">{whatItem.title}</h4>
                                        <h5 className="text  mx-auto">{whatItem.paragraph}</h5>

                                        <a className="btn bg-blue" href="#s" >View More</a>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}
export default Services;