/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./footer.css";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 
"react-icons/fa";

const Footer = () => {
    return(
        <footer className="footer bg-dark text-white py-6" id="footer">
            <div className="container">
                <div className="footer-content text-center">
                    <div className="footer-item-group grid">
                        <div className="footer-item">
                            <h3 className="footer-item-title">
                                portfolio
                            </h3>
                            <ul className="footer-item-links">
                                <li><a href="#">Web Design</a></li>
                                <li><a href="#">Breanding</a></li>
                                <li><a href="#">Mobile Design</a></li>
                                <li><a href="#">Blog</a></li>
                                <li><a href="#">Contact Us</a></li>

                            </ul>


                        </div>

                        <div className="footer-item">
                            <h3 className="footer-item-title">
                                about
                            </h3>
                            <ul className="footer-item-links">
                                <li><a href="#">the company</a></li>
                                <li><a href="#">Breanding</a></li>
                                <li><a href="#">Mobile Design</a></li>
                                <li><a href="#">Blog</a></li>
                                <li><a href="#">Contact Us</a></li>

                            </ul>


                        </div>
                        <div className="footer-item">
                            <h3 className="footer-item-title">
                                Gallery
                            </h3>
                            <ul className="footer-item-links">
                                <li><a href="#">Web Design</a></li>
                                <li><a href="#">Breanding</a></li>
                                <li><a href="#">Mobile Design</a></li>
                                <li><a href="#">Blog</a></li>
                                <li><a href="#">Contact Us</a></li>

                            </ul>


                        </div>
                        <div className="footer-item">
                            <h3 className="footer-item-title">
                               contact Us
                            </h3>
                            <ul className="footer-item-links">
                                <li><a href="#">Basic</a></li>
                                <li><a href="#">Map</a></li>
                                <li><a href="#">Contact Form</a></li>
                                <li><a href="#">Blog</a></li>
                                <li><a href="#">Contact Us</a></li>

                            </ul>


                        </div>

                    </div>

                    <div className="footer-social-icons flex flex-center">
                        <a href="https://facebook.com" className="bg-red flex flex-center">
                         <FaFacebookF/>
                        </a>
                        <a href="https://twitter.com" className="bg-red flex flex-center">
                         <FaTwitter/>
                        </a>
                        <a href="https://instagram.com" className="bg-red flex flex-center">
                         <FaInstagram/>
                        </a>
                        <a href="https://linkedin.com" className="bg-red flex flex-center">
                         <FaLinkedin/>
                        </a>
                    </div>
                   <div className="footer-bottom-text">
                    <p className="text fs-16"> Copyright &
                    copy; 2024. All right Reserved </p>

                   </div>
                </div>

            </div>
        </footer>
    )
}
export default Footer;