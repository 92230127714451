import React from "react";

import data from "../../constants/data";
import "./features.css";

const Features = () => {
    return (
        <section className="features py-6" id = "features">
            <div className="container">
                <div className="section-title bg-dark ">
                    <h2 className="text-upper text-white text-center">
                        Our Features
                    </h2>

                </div>
                <div className="features-content grid py-6">
                    {
                        data.features.map((feature, index) => {
                            return (
                                <div className="features-item text-center" key = {index}>
                                    <div className="features-item-icon">
                                        <img src={feature.icon} alt="feature icon"
                                            className="mx-auto" />

                                    </div>
                                    <div className="features-item-text">
                                        <h5>{feature.title}</h5>
                                        <p style={{ fontSize: 12 }} >{feature.Text}</p>

                                    </div>
                                </div>
                            )
                        })
                    }

                </div>

            </div>

        </section>
    )
}
export default Features;