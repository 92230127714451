import React from "react";
import "./whatsapp.css";
import calllogo from "../../assets/call.png"

import whatsapplogo from "../../assets/whatsapp.png";

const whatsapp=()=>{
    return(
 
        <div className="container">
            <div class="whats-app" style={{ zIndex: "6", left: "initial" }}>
                <a href="https://wa.me/917503920948?text=Hello How can I Help You ?" >
                    <img src={whatsapplogo} width="40" height="40" alt="aaaa" />
                </a>


            </div>

            <div className="fixed-tel" style={{ zIndex: "6", right: "initial" }} >
                <a class="fixed-tel" href="tel:+917503920948" title="Call Now"><i class="fas fa-phone-alt"></i>
                    <img src={calllogo} width="40" height="40" alt="aaaa" />
                </a>


            </div>


        </div>
      
    )
  
}
export default whatsapp;