import React from "react";
import data from "../../constants/data";
import "./team.css";

const Team = () => {
    return(
        <section className="team py-6 bg-blue" id="team">
            <div className="container">
                <div className="section-title bg-dark">
                    <h2 className="text-upper text-white text-center">

                        our team
                    </h2>
                </div>
                <div className="team-content grid py-6">
                    {
                        data.teams.map((teams, index) =>{
                            return(
                                <div className="team-item text-center text-white" key={index}>
                                    <img src = {teams.img} alt="team"  className="mx-auto" ></img>
                                    <p className="text-upper fw-7">{teams.name}</p>
                                    <span className="text-upper">{teams.post}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}
export default Team;