/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import data from "../../constants/data";
import "./packages.css";

const packages = () =>{
    return (
        <section className="packages py-6" id="packages">
            <div className="container">
                <div className="section-title bg-dark">
                    <h2 className="text-upper text-white text-center">Our Packages </h2>
                </div>
                <div className="packages-content grid py-6">
                    {
                    data.packages.map((packages, index) =>
                    {
                    
                       return(
                        <div className="package-item text-center mx-auto" key={index}>
                            <h3 className="package-item-title"> {packages.type}</h3>
                            <ul className="package-item-list">
                                {packages.service_list.map ((service, idx) => {
                                    return(
                                        <li key={idx}>{service}</li>
                                    )
                                    
                                  })
                                }
                            </ul>
                            <div className="package-item-price">
                                <span>{packages.price}</span> /year.
                            </div>
                            <a href="#" className="btn bg-blue">order now</a>

                        </div>
                       )

                    })
                    }
                </div>
            </div>
        </section>
    )
}
export default packages;