
 const what_we_do = [
   
    {
       
      image: require("../assets/App_Development.jpg"),
        title: "App Development",
        paragraph: "Developing scalable, innovative, and fully functional Android apps for businesses across multiple verticals. Our developers provide end-to-end android app development by harnessing the next-gen technologies to make them future-proof." 

    },
    {
       
      image: require("../assets/WebsiteDevelopment.jpg"),
        title: "Website Development",
        paragraph: "Look for the ideal solutions for web development as you hire the best website development company to cater to your individual needs." 

    },
    {
       
        image: require("../assets/digitalmark.jpg"),
          title: "Digital Marketing",
          paragraph: "We offer digital marketing services taking into account your objectives, needs and financial constraints.Search Engine Optimization methods are measurable that’s why their result-oriented factor is very important and that’s specifically how RichestSoft interests." 
  
      },
 ]
 
 const features = [

    {
        icon: require("../assets/webl.png"),
        title: "Website Development",
        Text: "Look for the ideal solutions for web development as you hire the best website development company to cater to your individual needs."
    },
    {
        icon: require("../assets/mobl.png"),
        title: "Mobile Development",
        Text: "The best mobile app company is there to provide technologically sound mobile development services with multi-compatibility benefits."
    },
    {
        icon: require("../assets/seol.png"),
        title: "SEO and Digital Marketing",
        Text: "Digital Marketing and SEO content will get to a new level with the exact solutions from our experts. Tell us about your preferences, and we will try to analyze your specific needs"
    },
    {
        icon: require("../assets/iosl.png"),
        title: "iOS Applications",
        Text: "Our Team Make cross plateform Application"
    },
    {
        icon: require("../assets/ecoml.png"),
        title: "Ecommerce Web-designing",
        Text: "BYTOSOFT IT Solution build Ecommerce Website to grow your business online."
    },
    {
        icon: require("../assets/digil.png"),
        title: "Digital Marketing",
        Text: "We offer digital marketing services taking into account your objectives, needs and financial constraints. At Bytosoft, our digital marketing experts have outstanding skills and in-depth understanding that will meet your project requirements."
    },
 ] 

 const packages = [
    {
        type:"Static Website",
        service_list: [
            'Custom Layout',
            '5 Static Page(HTML/PHP)',
            'Enquiry Form',
            'Free Website Maintenance',
            '1 Domain Name',
            'Free Support',
            'Basic SEO',
            '+ More'
        ],
        price:"Rs. 9999/- Only"
    },
    {
        type:"Dynamic website",
        service_list: [
            'Dynamic Design & Approval',
            '10 Static Page(HTML/PHP)',
            '3 Dynamic Page(PHP)',
            '1 Admin Page(PHP)',
            'Enquiry Form',
            '1 Domain Name',
            'Basic SEO',
            '+ More'
        ],
        price:"Rs. 14999/- Only"
    },
    {
        type:"Digital Marketing / SEO",
        service_list: [
            'Keywords Upto 10',
            'Min. duration 6 Months',
            'On-page SEO Upto 6 Pages',
            'Website Analysis Yes',
            'Keyword Research Yes',
            'Meta Tag optimization Yes',
            'Robot txt Yes',
            'Off-page SEo activities Yes'
        ],
        price:"Rs. 15000 /Mon - Only"
    },
 ]

 const projects =[
    {
    img: require("../assets/khelkud.png"),
    title: 'सांसद खेलकूद प्रतियोगिता काशी'
    },
    {
        img: require("../assets/sanskritik.png"),
        title: 'काशी सांसद सांस्कृतिक महोत्सव'
    },
    {
        img: require("../assets/shreebalajilogo2.png"),
        title: 'Shree Balaji Gold'
    },
    {
        img: require("../assets/photography.png"),
        title: 'काशी सांसद फोटोग्राफी प्रतियोगिता'
    },
    {
        img: require("../assets/vandan.png"),
        title: 'काशी वंदन'
    },
    {
        img: require("../assets/rao.png"),
        title: 'RaoIas'
    },
    {
        img: require("../assets/sheetal.jpg"),
        title: 'Sheetal Rajput Official'
    },
    {
        img: require("../assets/news.jpg"),
        title: 'News 24'
    },
 ]

 const teams= [
    {
        img: require("../assets/img1.png"),
        name: "Ashish Srivastava",
        post: 'CEO'

    },
    {
        img: require("../assets/1mg.png"),
        name: "Aniket Srivastava",
        post: 'Senior Developer'

    },
    {
        img: require("../assets/2mg.png"),
        name: "Rajesh.S",
        post: 'CTO'

    },
 ]



 export default { what_we_do , features , packages , projects , teams};