import React, { useState } from "react";
import logo from "../../assets/logosvg.svg"
import "./navbar.css"
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const close = () => setClick(false);
    return (
        <nav className="navbar">
            <div className="container flex navbar-content">
                <div className="brand-and-toggler flex">
                    <a href="index.html" alt=""
                        className="navbar-brand text-upper fw-7 fs-22 flex">
                        {/* <span className="text-white">byto</span>
                        <span>soft</span> */}
                        <img className="photo" src={logo} alt="" />

                    </a>
                    <button type="button" className="navbar-show-btn text-white"
                        onClick={() => handleClick()}>
                        <FaBars
                            size={26}>

                        </FaBars>
                    </button>
                </div>

              
                <div className={'navbar-collapse flex flex-center textdesign' + (click ? ' show-navbar' : '')}>
                     <button type="button"
                        className="navbar-hide-btn text-white"
                        onClick={() => close()}>
                        <FaTimes size={32}></FaTimes>

                    </button>
                    <ul className="navbar-nav text-upper text-white fw-6 ls-1 fs-20  text-center">
                        <li className="nav-item">
                            <a href="#header" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item">
                            <a href="#header" className="nav-link">Services</a>
                        </li>
                        <li className="nav-item">
                            <a href="#header" className="nav-link">Portfolio</a>
                        </li>
                        <li className="nav-item">
                            <a href="#header" className="nav-link">About</a>
                        </li>
                        <li className="nav-item">
                            <a href="#header" className="nav-link">Contact Us</a>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </nav>






    )
}
export default Navbar;