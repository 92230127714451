import React from "react";
import data from "../../constants/data";
import "./projects.css";

const Projects = () => {
    return(
        <section className="projects py-6" id="projects">
            <div className="container">
                <div className="section-title bg-dark">
                    <h2 className="text-upper text-white text-center">
                        our projects
                    </h2>
                </div>
                <div className="projects-content grid py-6">
                    {
                        data.projects.map((projects, index) =>{
                            return(
                                <div className="projects-item text-center" key={index}>
                                    <img src={projects.img} alt="project" className="mx-auto"></img>
                                    <h5 className="text-upper">{projects.title}</h5>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}
export default Projects;