import React from "react";
import facebook from "../../assets/icons8-facebook.svg"
import youtube from "../../assets/icons8-youtube.svg";
import linkin from "../../assets/icons8-in.svg";
import insta from "../../assets/icons8-instagram.svg"
import "./smallnav.css"


const Navbar = () => {

    return (
        <nav className="smallnavbar">
            <div className="container flex navbar-content">
                <div className="band-and-toggler flex">
                    <a href="index.html" alt="">
                        <span className="text-color">+91-</span>
                        <span className="text-color">7503920948</span>
                        {/* <img className="photo" src={device} alt="" /> */}

                    </a>

                </div>


                <div className="smallnavbar-collapse  text-dark">
                    <h1 className="text-color">Welcome to Bytosoft</h1>

                </div>

                <div class="social-container">

                    <a href="https://www.youtube.com/@bytosoft">
                        <img className="youtube social" src={youtube} alt="" />
                    </a>
                    {/* <img className="youtube social" src={facebook} alt="" /> */}

                </div>
                <div class="social-container">

                    <a href="https://www.facebook.com/bytosoft"
                    >
                        <img className="facebook social" src={facebook} alt="" />
                    </a>
                    {/* <img className="youtube social" src={facebook} alt="" /> */}

                </div>
                <div class="social-container">

                    <a href="https://www.twitter.com" >
                        <img className="twitter social" src={linkin} alt="" />
                    </a>
                    {/* <img className="youtube social" src={facebook} alt="" /> */}

                </div>

                <div class="social-container">

                    <a href="https://www.instagram.com/professional_website_designer1/"
                    >
                        <img className="instagram social" src={insta} alt="" />
                    </a>
                    {/* <img className="youtube social" src={facebook} alt="" /> */}

                </div>

            </div>




        </nav>






    )
}
export default Navbar;