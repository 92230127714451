import './App.css';
import './index.css';
import React from 'react';
import "./assets/js/script";
import Header from "./components/header/header";
import Services from './components/services/services';
import Features from './components/features/features';
import Packages from './components/packages/packages';
import Projects from './components/projects/projects';
import Team from './components/team/team';
import Footer from './components/footer/footer';
import Whatsapp from './components/whatsapp/whatsapp';
import ReactGA from 'react-ga4';

ReactGA.initialize("G-LE8FV1MZRJ");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});


function App() {
  return (
    <>
    <div className="App">
  
      <Header> </Header>
      <Services></Services>
      <Features></Features>
      <Packages></Packages>
      <Projects></Projects>
      <Team></Team>
      <Footer></Footer>
      <Whatsapp></Whatsapp>
      
     
    </div>

  
    
    </>
  );
}

export default App;
