/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import images from "../../constants/images";
import "./header.css";
import {FaPaperPlane} from "react-icons/fa";
import Navbar from "../navbar/navbar";
import Smallnav from "../navbar/smallnav";

const header=()=>{
    return(
        <header className="header flex" id="header">
            <Smallnav></Smallnav>
            <Navbar></Navbar>
            
            <div className="container">
             <div className="header-content grid text-center py-6 text-white">
                <div className="header-content-left">
                    <h1 className="text-upper header-title text-dark">WEBSITE AND ANDROID APP DEVELOPMENT COMPANY IN VARANASI</h1>
                    <p className="text-dark">Bytosoft Web Development & Digital marketing agency in Varanasi experience in Web Design, Web Development, Website Maintenance, Search Engine Optimisation (SEO), Mobile App Development, Google Ads, Social Media Management, Custom Application Development and more.</p>
                   
                
                   
                    <a href="#" className="btn btn-dark"><span>view more</span><FaPaperPlane/></a>
                </div>
                <div className="header-content-right">
                     <img  
                     src={images.headerimg2} alt="devices_images"/>
                    
                </div>
             </div>

            </div>
        </header>
        
    )
}
export default header;